import { render, staticRenderFns } from "./derate-manage-resolve.vue?vue&type=template&id=3dfe5648&scoped=true"
import script from "./derate-manage-resolve.vue?vue&type=script&lang=ts"
export * from "./derate-manage-resolve.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dfe5648",
  null
  
)

export default component.exports