import { render, staticRenderFns } from "./derate-manage-handled.vue?vue&type=template&id=35426b82&scoped=true"
import script from "./derate-manage-handled.vue?vue&type=script&lang=ts"
export * from "./derate-manage-handled.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35426b82",
  null
  
)

export default component.exports